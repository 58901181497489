
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required|max:100"
                  v-slot="{ errors }"
                  name="Nama Role"
                >
                  <CInput
                    label="Nama Role"
                    placeholder="Nama Role"
                    v-model="form.role_name"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CTextarea
                  label="Deskripsi Role"
                  placeholder="Deskripsi Role"
                  v-model="form.description"
                  rows="9"
                  :isValid="true"
                ></CTextarea>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <table class="table center-aligned-table table-bordered">
                  <thead>
                    <tr>
                      <th scope="row">
                        <strong>Menu</strong>
                      </th>
                      <th scope="row">
                        <strong>SubMenu</strong>
                      </th>
                      <th scope="row" style="width: 50%">
                        <strong>Aksi</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- assignment start -->
                    <template v-for="(field, index) in form.assignments">
                      <tr
                        :key="index"
                        v-if="
                          field[Object.keys(field)[0]] == 0 ||
                          field[Object.keys(field)[0]] == 1
                        "
                      >
                        <td scope="row">
                          <strong>{{ index }}</strong>
                        </td>
                        <td scope="row">-</td>
                        <td scope="row">
                          <b-form-checkbox
                            inline
                            v-for="(val, index_field) in field"
                            :key="index + '_' + index_field"
                            v-model="form.assignments[index][index_field]"
                            value="1"
                            unchecked-value="0"
                            >{{ index_field }}</b-form-checkbox
                          >
                        </td>
                      </tr>
                      <template v-else>
                        <tr
                          :key="index_child"
                          v-for="(child, index_child) in field"
                        >
                          <td scope="row">
                            <strong
                              v-if="Object.keys(field)[0] == index_child"
                              >{{ index }}</strong
                            >
                          </td>
                          <td scope="row">{{ index_child }}</td>
                          <td scope="row">
                            <b-form-checkbox
                              inline
                              v-for="(val, index_child_field) in child"
                              :key="
                                index +
                                '_' +
                                index_child +
                                '_' +
                                index_child_field
                              "
                              v-model="
                                form.assignments[index][index_child][
                                  index_child_field
                                ]
                              "
                              value="1"
                              unchecked-value="0"
                              >{{ index_child_field }}</b-form-checkbox
                            >
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else
                  >Simpan</CButton
                >
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormCheckbox } from "bootstrap-vue";
import Swal from "sweetalert2";
import _ from "lodash";
import form from "./role";
export default {
  name: "FormAbkUmum",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      errorMessage: null,
      form: _.cloneDeep(form),
      loading: false,
      item: null,
    };
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.id = item.id;
        this.form.role_name = item.role_name;
        this.form.description = item.description;
        console.log(this.form.assignments);
        Object.keys(this.form.assignments).forEach((key) => {
          Object.keys(this.form.assignments[key]).forEach((key2) => {
            if (
              this.form.assignments[key][key2] == 0 ||
              this.form.assignments[key][key2] == 1
            ) {
              this.form.assignments[key][key2] = item.assignments[key][key2];
            } else {
              Object.keys(this.form.assignments[key][key2]).forEach((key3) => {
                this.form.assignments[key][key2][key3] =
                  item.assignments[key][key2][key3];
              });
            }
          });
        });
      }
    },
  },
  methods: {
    resetForm() {
      this.form = _.cloneDeep(form);
    },
    populateInput(item) {
      this.form = _.cloneDeep(form);
      this.item = item;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("role/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("role/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.nama_layanan = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
